dl {
  display: grid;
  grid-template-columns: max-content auto;
}

dt {
  grid-column-start: 1;
  padding: 3px 5px 0 0;
}

dd {
  grid-column-start: 2;
  padding-bottom: 10px;
}
